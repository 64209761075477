.financialhouse {
  background-color: #f5f5f5;
}

.financialhouse .logo {
  background-color: #fff !important;
}

.financialhouse .listedOption .item input[type='radio']:checked ~ label:before {
  border-color: #da291c;
}

.financialhouse .listedOption .item label:after {
  background: #da291c;
}

.financialhouse .pButton .btn-primary {
  background: linear-gradient(69.15deg, #da291c 13.79%, #da291c 86.21%);
}
.financialhouse .capsule {
  border-radius: 18px;
  border-top-left-radius: 0;
  box-shadow: 0px 2px 10px #00000060;
}

.financialhouse .full-css-select {
  height: 18px;
  padding: 0;
  border: 1px solid white;
  display: flex;
  align-items: center;
  border-radius: 3px;
}

.financialhouse .full-css-select select {
  width: auto;
  margin: 0;
  padding: 2px;
  height: 18px !important;
  top: 0;
  line-height: 1 !important;
}

.financialhouse .full-css-select::after {
  display: none;
}

.financialhouse .card-input {
  background: transparent url('../../img/icon/fh-card-null.svg') no-repeat right
    24px center !important;
  background-size: 32px 20px !important;
}

.financialhouse .card-input.mastercard {
  background: transparent url('../../img/icon/fh-mastercard.svg') no-repeat
    right 24px center !important;
  background-size: 30px 18px !important;
}

.financialhouse .card-input.visa {
  background: transparent url('../../img/icon/fh-visa.svg') no-repeat right 24px
    center !important;
  background-size: 40px 12px !important;
}

.financialhouse .card-input.unionpay {
  background: transparent url('../../img/icon/fh-unionpay.png') no-repeat right
    24px center !important;
  background-size: 38px 25px !important;
}

.financialhouse .card-input.error {
  background: transparent url('../../img/icon/fh-card-null.svg') no-repeat right
    24px center !important;
  background-size: 32px 20px !important;
}

.financialhouse .form-group.full .form-control {
  font-size: 20px;
  border-bottom: 2px solid #ebebeb;
  padding-left: 60px;
}
.financialhouse .pageTitle {
  box-shadow: none;
  border-bottom: 2px solid #ebebeb;
  color: #606060;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  padding: 13px 24px;
}

.financialhouse .pageTitle img {
  margin-right: 6px;
}

.financialhouse .doubleCol .form-group.full {
  border-left: 2px solid #ebebeb;
}

.financialhouse .pContent {
  padding-bottom: 0;
}

.financialhouse {
  background-image: url('../../img/fh-bg.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: contain;
  background-position: calc(50% + 375px) center;
}

@media only screen and (max-width: 767px) {
  .financialhouse .pageTitle {
    top: 196px;
  }

  body.financialhouse {
    padding: 246px 0 57px 0 !important;
    background-image: none;
    background-color: white;
  }

  .financialhouse .capsule {
    border-radius: 0;

    box-shadow: none;
    background-color: white;
  }
}

.financialhouse .waiting-icon {
  color: #da291c;
}
