.hofi .pHeader {
  background: #001c91;
  background-image: none;
  border-bottom: 6px solid #22d6d6;
}

.hofi .listedOption .item input[type='radio']:checked ~ label:before {
  border-color: #001c91;
}

.hofi .listedOption .item label:after {
  background: #001c91;
}

.hofi .pButton .btn-primary {
  background: linear-gradient(69.15deg, #001c91 13.79%, #001c91 86.21%);
}
.hofi .logo {
  display: none;
}

@media (min-width: 768px) {
  .hofi .logo {
    display: block;
    text-align: right;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .hofi .logo img {
    width: 140px;
  }
}

.hofi .waiting-icon {
  color: #001c91;
}
