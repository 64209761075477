.bankyprocessing .pHeader {
  color: #222;
  background: #ffce32;
  border-bottom: 6px solid #1d63ff;
}
.bankyprocessing .pHeader .amount sup {
  color: #222 !important;
}
.bankyprocessing .pHeader .name {
  color: #222;
}

.bankyprocessing .full-css-select select {
  color: #222;
}

.bankyprocessing .logo {
  display: none;
}

.bankyprocessing .logo img {
  height: 35px;
}

@media (min-width: 768px) {
  .bankyprocessing .logo {
    display: block;
    text-align: right;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .bankyprocessing .pButton .btn-primary {
    background: #1d63ff;
  }
}

.bankyprocessing .waiting-icon {
  color: #1d63ff;
}
