.item-logo {
  max-width: 100px;
  max-height: 20px;
}

.pContent {
  min-height: 60px;
  /* Momentum Scrolling on iOS Overflow Elements */
  -webkit-overflow-scrolling: touch;
}

.loading-pContent {
  position: relative;
}

.loading {
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 0;
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading.active {
  z-index: 1999;
  opacity: 1;
  visibility: visible;
}

i.loading-icon {
  background: url('../img/icon/loading.svg') no-repeat;
  display: block;
  width: 40px;
  height: 40px;
}

.pageTitle {
  text-transform: uppercase;
  z-index: 100;
}

.cursor-copy {
  cursor: copy;
}

.cursor-pointer {
  cursor: pointer;
}

/* Tooltip style */
.custom-tooltip .tooltip-inner {
  background: #383f46;
  background-image: linear-gradient(-180deg, #3a434c 3%, #303234 100%);
  color: #fff;
}

.custom-tooltip .tooltip-arrow:before,
.custom-tooltip .tooltip-arrow {
  border-top-color: #383f46;
}

/* success card input */
.card-input.success {
  background: transparent url('../img/icon/card-success.png') no-repeat right
    24px center !important;
  background-size: 28px 20px !important;
}

.listedOption .item input[type='checkbox'] {
  display: none;
}

.listedOption .item input[type='checkbox']:checked ~ label:after {
  display: block;
}

.listedOption .item input[type='checkbox']:checked ~ label:before {
  border-color: #286efa;
}

/* select */
select.form-control {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('../img/icon/arrow-down.png') !important;
  background-repeat: no-repeat !important;
  background-position: 97% center !important;
  padding-right: 35px !important;
}

@media only screen and (max-width: 425px) {
  .loading {
    position: fixed;
  }
}
