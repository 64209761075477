.kanky .pHeader {
  background: #e83338;
  background-image: none;
  border-bottom: 6px solid #2cabc8;
}
.kanky .pHeader .amount sup {
  font-weight: 700;
  color: rgba(255, 255, 255, 0.8);
}
.kanky .pHeader .name {
  color: #fff;
  font-weight: 500;
}
.kanky .listedOption .item input[type='radio']:checked ~ label:before {
  border-color: #e83338;
}
.kanky .listedOption .item label:after {
  background: #e83338;
}
.kanky .pButton .btn-primary {
  background: linear-gradient(69.15deg, #e83338 13.79%, #e83338 86.21%);
}
.kanky .logo {
  display: none;
}
@media (min-width: 768px) {
  .kanky .logo {
    display: block;
    text-align: right;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .kanky .logo img {
    width: 140px;
  }
}

.kanky .waiting-icon {
  color: #e83338;
}
