.fastpayzone .pHeader {
  background: #493657;
  background-image: none;
  border-bottom: 6px solid #ce7da5;
}

.fastpayzone .listedOption .item input[type='radio']:checked ~ label:before {
  border-color: #493657;
}

.fastpayzone .listedOption .item label:after {
  background: #493657;
}

.fastpayzone .pButton .btn-primary {
  background: linear-gradient(69.15deg, #493657 13.79%, #493657 86.21%);
}

.fastpayzone .waiting-icon {
  color: #493657;
}
