.jtnprocessing .pHeader {
  background:   #FEE9E6;
  border-bottom: 6px solid #ff6300;
 padding: 13px 22px 10px;;
}

.jtnprocessing .pHeader .row {
 align-items: center;
}


.jtnprocessing .pHeader .amount {
  color:#360802;
  margin-top: 23px;
  font-size: 28px;
}

.jtnprocessing .pHeader .amount sup {
  color:#360802;
}
.jtnprocessing .pHeader .name {
  color:#626366;
  margin-top: 0.3rem;
}


.jtnprocessing .listedOption .item input[type='radio']:checked ~ label:before {
  border-color: #ff6300;
}

.jtnprocessing .listedOption .item label:after {
  background: #ff6300;
}

.jtnprocessing .pButton .btn-primary {
  background-color:#F73B20;
  color:#fff
}
.jtnprocessing .pButton .btn-default {
  background-color: rgba(247, 59, 32, 0.05);
  color:#F73B20
}

.jtnprocessing .logo {
   display: none;
}

.jtnprocessing .alertbox {
  padding: 114px 24px !important;
}

.jtnprocessing .pHeader .logo {
  display: unset; 
  }

  .jtnprocessing .pHeader .right .full-css-select select {
    color: #F73B20;
    background: #fff;
    border: 1px solid rgba(247, 59, 32, 0.2);
    border-radius: 4px;

  }
  

  .jtnprocessing .pHeader .right .full-css-select{
    margin: 4px 0 26px;
  }



  .jtnprocessing .pHeader .right .full-css-select:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-radius: 1px;
    top: 50%;
    right: 7px;
    margin-top: -2px;
    z-index: 99;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #f73b20 !important; /* Match text color */
}
  
  .jtnprocessing .pHeader .right .full-css-select:hover{
    border-color: unset;
    border: unset;
  }
  

  .jtnprocessing .pageTitle {
    color: #626366;
  }


  .jtnprocessing  .waiting-header , .jtnprocessing  .pContent .payment-title {
    color: #F73B20;
  }

  .jtnprocessing  .waiting-text  {
    text-align: center;
  color: #36080257;
  line-height: 1.4;
  font-size: 13px;
  font-weight: 500;
  margin: 0 auto;
  }

.jtnprocessing .waiting-icon {
  color: #F73B20;
}
