@font-face {
  font-family: 'HelveticaNowDisplay-Regular';
  src: url('../../fonts/HelveticaNowDisplayRegular/font.woff2') format('woff2'),
    url('../../fonts/HelveticaNowDisplayRegular/font.woff') format('woff');
}
@font-face {
  font-family: 'HelveticaNowDisplay-Bd';
  src: url('../../fonts/HelveticaNowDisplayBd/font.woff2') format('woff2'),
    url('../../fonts/HelveticaNowDisplayBd/font.woff') format('woff');
}

.speedy {
  font-family: 'HelveticaNowDisplay-Regular', sans-serif;
  background-image: url('../../img/speedyteller_bg.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.speedy .pHeader {
  background: #625f5f;
  background-image: none;
  border-bottom: 8px solid #d5d5d5;
}
.speedy .listedOption .item input[type='radio']:checked ~ label:before {
  border-color: #000000;
}
.speedy .listedOption .item label:after {
  background: #000000;
}

.speedy .pButton .btn-primary {
  background: linear-gradient(69.15deg, #000000 13.79%, #000000 86.21%);
}

.speedy .logo {
  display: none;
}

.speedy .logo img {
  height: 30px;
}

@media (min-width: 768px) {
  .speedy .logo {
    display: block;
    text-align: right;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

.speedy .waiting-icon {
  color: #000000;
}
