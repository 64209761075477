.jetoncash .pHeader {
  background: #27272b;
  background-image: none;
  border-bottom: 6px solid #b1bbc9;
}

.jetoncash .listedOption .item input[type='radio']:checked ~ label:before {
  border-color: #ff6300;
}

.jetoncash .listedOption .item label:after {
  background: #ff6300;
}

.jetoncash .pButton .btn-primary {
  background: linear-gradient(69.15deg, #ff6300 13.79%, #ff6300 86.21%);
}

.jetoncash .waiting-icon {
  color: #ff6300;
}
