.payeerfield .pHeader {
  background: #88498f;
  background-image: none;
  border-bottom: 6px solid #e0cba8;
}

.payeerfield .listedOption .item input[type='radio']:checked ~ label:before {
  border-color: #88498f;
}

.payeerfield .listedOption .item label:after {
  background: #88498f;
}

.payeerfield .pButton .btn-primary {
  background: linear-gradient(69.15deg, #ff6542 13.79%, #ff6542 86.21%);
}

.payeerfield .waiting-icon {
  color: #ff6542;
}
